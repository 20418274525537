<template>
  <div class="content md flex-box vertical">
    <div class="data-title">
      <div class="title-left flex-box">
        <span>共{{ pagination.total }}条记录</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item label="申请类型">
          <a-select
            style="width: 180px"
            placeholder="选择申请类型筛选"
            @change="updateList"
            :options="tempOptions"
            v-model="form.temp_id"
            show-search
            option-filter-prop="children"
            allowClear
          >
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="getList">查询</a-button>
        <!-- <a-button type="primary" @click="exportData" :loading="exporting">{{
          exporting ? "正在导出" : "数据导出"
        }}</a-button> -->
      </a-space>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ y: h }"
        @change="handleTableChange"
      >
        <template v-slot:name="text">
          <open-data type="userName" :openid="text" />
        </template>
        <template v-slot:action="text, record">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="showDetail(record)">查看详情</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { dealApply } from "@/common/js/apply";
import { getTemple } from "@/common/js/storage";
import { dataConvertOptions } from "../../../common/js/tool";
export default {
  name: "VolunteerApplyList",
  data() {
    return {
      loading: false,
      list: [],
      form: {},
      h: 500,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      exporting: false,
      tempOptions: [],
      columns: [
        { title: "申请类型", dataIndex: "tempName" },
        { title: "申请时间", dataIndex: "create_time" },
        {
          title: "申请人",
          customRender: (text) => {
            return text?.volunteer?.name || "-";
          },
        },
        { title: "状态", dataIndex: "statusText" },
        { title: "操作", key: "action", fixed: "right", width: 120, scopedSlots: { customRender: "action" } },
      ],
    };
  },
  created() {
    this.getTemp();
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    getTemp() {
      this.$axios({
        url: "/admin/apply-temp",
        method: "GET",
        params: {
          sort: "-id",
          "filter[is_oa]": 6, // 志工小程序业务
        },
      }).then((res) => {
        this.tempOptions = dataConvertOptions(res.data);
      });
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let url = "/admin/live/export?";
      url += `temple_id=${getTemple()}`;
      let form = this.form;
      let fileName = "数据列表";
      if (form.temp_id) {
        url += `&temp_id=${form.temp_id}`;
      }
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          this.exporting = false;
          const content = res;
          const blob = new Blob([content]);
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName + ".xlsx";
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .catch(() => {
          this.exporting = false;
        });
    },
    showDetail(record) {
      this.$store.commit("setApply", record);
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    getList() {
      if (this.loading) return;
      this.loading = true;
      let form = this.form;
      let { current, pageSize } = this.pagination;
      let url = `/admin/apply?sort=-id&expand=extraApply,volunteer&page=${current}&pageSize=${pageSize}&temple_id=${getTemple()}&is_oa=6`;
      if (form.temp_id) {
        url += `&filter[temp_id]=${form.temp_id}`;
      }
      this.$axios({ url, noTempleFilter: true }).then(
        (res) => {
          let list = res.data;
          list.forEach((apply) => {
            dealApply(apply);
          });
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
  },
};
</script>

<style scoped lang="less"></style>
